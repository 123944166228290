import React, {ChangeEvent, useEffect, useState} from 'react';
import {sendSMS} from '../../../lib/network/api';
import {IBannerNewComponentProps, ISmsSentModalProps} from '../../../lib/types/components';
import {ICountrySelectComponentProps} from "../../../lib/types/components.us";
import {countryCodes} from "../../../lib/utils/constants/CountryCode";
import {
    GET_APP_FACEBOOK_URL,
    GET_APP_FB_URL,
    numberValidationMapping,
    SENDING_MSG,
    USER_COUNTRY_CODE
} from '../../../lib/utils/constants/GlobalConstants';
import {
    currentPageURL,
    dynamicCssSize,
    getCookie,
    getCountryFromLocale,
    getOnlineStatus, isMobile,
} from '../../../lib/utils/constants/PagesConstants';
import CountrySelectComponent from "../CountrySelect/CountrySelectComponent";
import SmsSentModal from '../SmsSentModal/SmsSentModal';
import {useRouter} from "next/router";
import CustomCarouselComponent from "../CustomCarousel/CustomCarouselComponent";
import CustomImageComponent from "../CustomImage/CustomImageComponent";

const MAX_MOBILE_NUMBER_LENGTH = 15;
const MIN_MOBILE_NUMBER_LENGTH = 7;

const BannerNewComponentV2 = (props: IBannerNewComponentProps) => {
    const {
        bannerHomeData,
        smsSentModalData,
        locale,
        setIsToastOpen,
        smsVariablesTemplate,
        mobileNumber,
        countryCodeText,
        disableAppDownload = false,
        hideMobileNumberSection = false
    } = props;
    const router = useRouter()
    const isDwarfBanner: boolean = currentPageURL === GET_APP_FACEBOOK_URL || currentPageURL === GET_APP_FB_URL;

    const [mobileBannerAssets, setMobileBannerAsstes] = useState(props.isGetAppPage ? {
        bannerImageFg: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
        bannerBgImage: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
        bannerHeadlineImage: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
    } : {
        bannerImageFg: bannerHomeData.mobileFg,
        bannerBgImage: bannerHomeData.bgColorMobile,
        bannerHeadlineImage: bannerHomeData.mainTextImgMobile
    });

    const [desktopBannerAssets, setDesktopBannerAssets] = useState(props.isGetAppPage ? {
        bannerImageFg: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
        bannerBgImage: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7",
        bannerHeadlineImage: "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
    } : {
        bannerImageFg: bannerHomeData.desktopFg,
        bannerBgImage: bannerHomeData.bgColorDesktop,
        bannerHeadlineImage: bannerHomeData.mainTextImgDesktop
    });

    useEffect(() => {
        const utmBanner = router.query.utm_banner;
        const utmBgColor = router.query.utm_banner_color;

        if (utmBanner) {
            setMobileBannerAsstes({
                bannerImageFg: "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-mob-fg.webp",
                bannerBgImage: "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-mob-bg.webp",
                bannerHeadlineImage: "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-mob-text.webp"
            })
            setDesktopBannerAssets({
                bannerImageFg: "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-desk-fg.webp",
                bannerBgImage: "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-desk-bg.webp",
                bannerHeadlineImage: "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-desk-text.webp"
            })
        } else {
            setMobileBannerAsstes({
                bannerImageFg: bannerHomeData.mobileFg,
                bannerBgImage: bannerHomeData.bgColorMobile,
                bannerHeadlineImage: bannerHomeData.mainTextImgMobile
            })
            setDesktopBannerAssets({
                bannerImageFg: bannerHomeData.desktopFg,
                bannerBgImage: bannerHomeData.bgColorDesktop,
                bannerHeadlineImage: bannerHomeData.mainTextImgDesktop
            })
        }

        if (utmBgColor) {
            setMobileBannerAsstes({
                bannerImageFg: utmBanner ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-mob-fg.webp" : bannerHomeData.mobileFg,
                bannerBgImage: "#" + utmBgColor,
                bannerHeadlineImage: utmBanner ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-mob-text.webp" : bannerHomeData.mainTextImgMobile
            })
            setDesktopBannerAssets({
                bannerImageFg: utmBanner ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-desk-fg.webp" : bannerHomeData.desktopFg,
                bannerBgImage: "#" + utmBgColor,
                bannerHeadlineImage: utmBanner ? "https://d3g4wmezrjkwkg.cloudfront.net/website/images/homePage/" + utmBanner + "-banner-desk-text.webp" : bannerHomeData.mainTextImgDesktop
            })
        }
    }, [props.bannerHomeData])

    const [smsSent, setSmsSent] = useState<boolean>(false);
    const [smsSentMsg, setSmsSentMsg] = useState<string>(SENDING_MSG);
    const [numberError, setNumberError] = useState<string>("");
    const [number, setNumber] = useState<string>("");
    const [countryCode, setCountryCode] = useState<string>("-");

    useEffect(() => {
        const country = getCountryFromLocale(getCookie(USER_COUNTRY_CODE)).toUpperCase();
        countryCodes.forEach((item) => {
            if (item.code === country) setCountryCode(item.dial_code)
        })
    }, [])

    const countrySelectComponentProps: ICountrySelectComponentProps = {
        countryCode: countryCode,
        setCountryCode: setCountryCode,
        locale: props.locale,
        disableAppDownload
    }

// Function to handle number input
    const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const contry = getCountryFromLocale(props.locale);
        let countryConfig = numberValidationMapping[contry]
            ? numberValidationMapping[contry]
            : numberValidationMapping["DEFAULT"];
        if (event.target.value.length <= countryConfig.max) {
            setNumber(event.target.value);
        }
    };
    const validatePhoneNumber = (
        number: string,
        country: string
    ): string | undefined => {
        if (!number.length) {
            return mobileNumber.validMobileNumber;
        }
        let countryConfig = numberValidationMapping[country]
            ? numberValidationMapping[country]
            : numberValidationMapping["DEFAULT"];
        if (countryConfig) {
            if (number.length < countryConfig.min || number.length > countryConfig.max) {
                return mobileNumber.valid10digitMobileNumber;
            }
        }
    };
// function to handle submit number button
    const handleSubmit = (): void => {
        if (getOnlineStatus() === false) {
            setIsToastOpen(true);
            return;
        }
        let errorMessage: string | undefined = validatePhoneNumber(
            number,
            getCountryFromLocale(props.locale)
        );
        if (errorMessage) {
            setNumberError(errorMessage);
        } else {
            setSmsSentMsg(SENDING_MSG);
            setSmsSent(true);
            sendSMS(
                number,
                countryCode.substring(1),
                setSmsSentMsg,
                smsSentModalData.text1,
                smsVariablesTemplate
            );
            setNumberError("");
            const html: HTMLHtmlElement | null = document.querySelector("html");
            if (html !== null) {
                html.style.overflowY = "hidden";
            }
        }
    };

    const smsSentModalProps: ISmsSentModalProps = {
        smsSent: smsSent,
        smsSentMsg: smsSentMsg,
        setSmsSent: setSmsSent,
        number: number,
        smsSentModalData: smsSentModalData
    }

    return (
        bannerHomeData ?
            <>
                <style jsx>{`
                  .mainContainer {
                    background-color: ${desktopBannerAssets.bannerBgImage};
                    font-family: var(--font-primary);
                    position: relative;
                  }
                  
                  .banner-background-image {
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                  }
                  
                  .banner-background-image img {
                    margin: 0;
                    padding: 0;
                    display: block;
                    height: 100%;
                    width: 100%;
                    object-position: top center;
                    object-fit: cover;
                  }

                  .bannerImageFg {
                    width: 0;
                    height: 0;
                    min-width: 100%;
                    max-width: 100%;
                    min-height: 100%;
                    max-height: 100%;
                    object-fit: contain;
                    object-position: bottom center;
                  }

                  .bannerHeadlineImage {
                    width: 100%;
                    height: auto;
                  }

                  .innerContainer {
                    background-repeat: no-repeat;
                    background-position: center;
                    position: relative;
                  }

                  .styledRow {
                    height: 100% !important;
                    flex-direction: row !important;
                  }

                  .mobileImageContainer {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    min-height: 540px;
                    padding: 0;
                  }

                  .mobileImageContainer_FB {
                    display: flex;
                    justify-content: center;
                    position: relative;
                    min-height: 499px;
                    padding: 0;
                  }

                  .rightContainer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                  }

                  .rightContainer_FB {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-bottom: 0;
                  }

                  .logoContainer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 199px;
                    height: 100px;
                    position: relative;
                    margin-top: 10px;
                  }

                  .logoContainer_FB {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 199px;
                    height: 100px;
                    position: relative;
                    margin-top: 10px;
                  }

                  .bannerText {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    text-align: center;
                    position: relative;
                    width: 100%;
                    height: 266px;
                    margin-top: 48px;
                    margin-bottom: 15px;
                  }

                  .bannerText_FB {
                    display: flex;
                    justify-content: center;
                    align-items: flex-end;
                    text-align: center;
                    position: relative;
                    width: 326px;
                    height: 210px;
                    margin-top: 48px;
                    margin-bottom: 20px;
                  }

                  .mobileInputContainer {
                    width: 100%;
                    margin-bottom: 30px;
                  }

                  .inputContainer {
                    display: flex;
                    width: 100%;
                    height: 45px;
                    border-radius: 4.4px;
                    margin-bottom: 13px;
                  }

                  .inputContainer .inputBox {
                    height: auto;
                    width: 100%;
                    padding: .375rem .25rem;
                    border-radius: 5px;
                    transform: skewX(-10deg);
                    box-shadow: 0 8px 10px 5px rgb(0 0 0 / 25%);
                    background: rgba(0, 0, 0, 0.5);
                    border: 1px solid rgba(255, 255, 255, .4);
                    border-image-slice: 1;
                  }

                  .inputBox input {
                    height: 100%;
                    width: 100%;
                    font-weight: 400;
                    color: #ffffff;
                    text-align: center;
                    transform: skewX(10deg);
                    background: rgba(0, 0, 0, 0);
                    border: none;
                    text-transform: uppercase;
                  }

                  .inputContainer input::placeholder {
                    color: rgba(255, 255, 255, 0.75);
                    text-align: center;
                    font-size: 14px;
                    opacity: 0.75;
                  }

                  .errorText {
                    font-weight: 300;
                    font-size: 16px;
                    margin: 0 0 8px;
                    color: white;
                    text-align: center;
                  }

                  .smsButtonContainer {
                    width: 97%;
                  }

                  .smsButtonContainer > button {
                    padding: 0;
                    width: 100%;
                    height: 45px;
                    background: rgba(177, 253, 43, 1);
                    background: linear-gradient(to bottom, rgba(177, 253, 43, 1) 0%, rgba(49, 235, 111, 1) 100%);
                    text-transform: uppercase;
                    font-family: var(--font-primary);
                    font-size: ${dynamicCssSize(24, 18)};
                    color: #1a1a1a;
                    transform: skewX(-10deg);
                    box-shadow: 0 8px 10px 5px rgb(0 0 0 / 25%);
                    font-weight: 500;
                    letter-spacing: -0.38px;
                    border-radius: 8px;
                    border: 0;
                    outline: none;
                  }

                  .smsButtonContainer > button > p {
                    background: linear-gradient(121.19deg, rgba(133, 239, 49, 0) 25.73%, rgba(255, 255, 255, .3) 45.27%, rgba(133, 239, 49, 0) 62.27%), rgba(255, 255, 255, 0);
                    height: 100%;
                    transform: skewX(10deg);
                    padding: ${dynamicCssSize(12, 5)} ${dynamicCssSize(20, 10)};
                    margin: 0;
                    animation-duration: 60s;
                    animation-fill-mode: forwards;
                    animation-iteration-count: infinite;
                    animation-name: placeholderShimmer;
                    animation-timing-function: linear;
                    display: flex;
                    align-items: center;
                    justify-content: space-around;
                    line-height: 1;
                  }

                  .withdrawalContainer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    margin-bottom: 20px;
                  }

                  .withdrawalContainer > p {
                    font-size: 14px;
                    font-weight: 600;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: normal;
                    letter-spacing: normal;
                    color: #fff;
                  }

                  .withdrawalContainer > div {
                    display: flex;
                    flex-direction: row;
                    max-width: 400px;
                    justify-content: center;
                    align-items: center;
                    position: relative;
                    width: 400px;
                    height: 35px;
                  }

                  .fixedDownloadButtonContainer {
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    -webkit-transition: .3s;
                    transition: .3s;
                    box-shadow: 0 -2px 2px 0 rgb(0 0 0 / 8%);
                    padding: 10px 20px;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                  }

                  .hamburgerContainer {
                    position: absolute;
                    top: 25px;
                    right: 15px;
                    z-index: 200000;
                  }

                  @media screen and (max-width: 767px) {
                    .mainContainer {
                      padding: 0;
                      background-color: ${mobileBannerAssets.bannerBgImage};
                    }

                    .innerContainer {
                      padding: 0;
                    }

                    .styledRow {
                      flex-direction: column-reverse !important;
                      justify-content: center;
                      align-items: center;
                      width: 100%;
                      margin: 0;
                    }

                    .rightContainer {
                      margin-bottom: 10px;
                    }

                    .mobileImageContainer {
                      min-height: 355px;
                      width: 100%;
                      padding: 0;
                      margin: 0;
                    }

                    .mobileImageContainer_FB {
                      min-height: 314px;
                      margin: 45px 0 0 0;
                    }

                    .rightContainer {
                      margin: 55px 0 5px 0;
                      min-height: 125px;
                      padding: 0;
                    }

                    .logoContainer {
                      width: 215px;
                      height: 102px;
                    }

                    .logoContainer_FB {
                      margin-top: 0;
                      width: 215px;
                      height: 70px;
                    }

                    .bannerText {
                      width: 280px;
                      height: 100px;
                      margin: 0;
                    }

                    .bannerText_FB {
                      display: none;
                    }

                    .smsButtonContainer {
                      display: none !important;
                    }

                    .errorText {
                      display: none;
                    }

                    .withdrawalContainer {
                      display: none !important;
                    }

                    .mobileInputContainer {
                      display: none;
                    }
                  }

                  @-webkit-keyframes placeholderShimmer {
                    0% {
                      background-position: -10000px 0
                    }

                    100% {
                      background-position: 10000px 0
                    }
                  }

                  @keyframes placeholderShimmer {
                    0% {
                      background-position: -10000px 0
                    }

                    100% {
                      background-position: 10000px 0
                    }
                  }
                `}
                </style>
                <div
                    className={"mainContainer container-fluid"}
                    id="banner"
                >
                    <CustomImageComponent
                        layout={'fill'}
                        objectFit={'cover'}
                        src={isMobile ? mobileBannerAssets.bannerBgImage : desktopBannerAssets.bannerBgImage}
                        alt={"winzo main background image"}
                        priority={true}
                    />
                    <div className={"innerContainer container"}>
                        <div className={"styledRow row"}>
                            <div
                                className={isDwarfBanner ? "mobileImageContainer_FB col-sm-8" : "mobileImageContainer col-sm-8"}>
                                <picture style={{width: "100%"}}>
                                    <source srcSet={mobileBannerAssets.bannerImageFg} media="(max-width: 767px)"/>
                                    <img className={"bannerImageFg"} src={desktopBannerAssets.bannerImageFg}
                                         alt={bannerHomeData.mobileImgAlt}/>
                                </picture>
                            </div>
                            <div className={isDwarfBanner ? "rightContainer_FB col-md-4" : "rightContainer col-md-4"}>
                                {
                                    bannerHomeData.mainTextImg ?
                                        <CustomCarouselComponent>
                                            {
                                                bannerHomeData.mainTextImg.map((image, index) => {
                                                    return (
                                                        <div key={"bannerHeadlineImage_" + index}
                                                             className={isDwarfBanner ? "bannerText_FB" : "bannerText"}>
                                                            <picture style={{width: "100%"}}>
                                                                <source srcSet={image.mobile}
                                                                        media="(max-width: 767px)"/>
                                                                <img src={image.desktop}
                                                                     className={"bannerHeadlineImage"}
                                                                     alt={"winzo-main-text"}/>
                                                            </picture>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </CustomCarouselComponent>
                                        :
                                        <div className={isDwarfBanner ? "bannerText_FB" : "bannerText"}>
                                            <picture style={{width: "100%"}}>
                                                <source srcSet={mobileBannerAssets.bannerHeadlineImage}
                                                        media="(max-width: 767px)"/>
                                                <img src={desktopBannerAssets.bannerHeadlineImage}
                                                     className={"bannerHeadlineImage"} alt={"winzo-main-text"}/>
                                            </picture>
                                        </div>
                                }
                                {!hideMobileNumberSection && <div className={"mobileInputContainer"}>
                                    <div className={"inputContainer"}>
                                        <CountrySelectComponent {...countrySelectComponentProps} />
                                        <div className={"inputBox"}>
                                            <input
                                                type="text"
                                                maxLength={MAX_MOBILE_NUMBER_LENGTH}
                                                minLength={MIN_MOBILE_NUMBER_LENGTH}
                                                onInput={(e) => {
                                                    e.currentTarget.value = e.currentTarget.value.replace(/[^0-9]/g, '').replace(/(\..*)\./g, '$1');
                                                }}
                                                value={number}
                                                onChange={(e) => handleInputChange(e)}
                                                onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
                                                    if (e.key === "Enter") {
                                                        handleSubmit()
                                                    }
                                                }
                                                }
                                                placeholder={bannerHomeData.text3}
                                                disabled={disableAppDownload}
                                            >
                                            </input>
                                        </div>
                                    </div>
                                    <div className={"smsButtonContainer"}>
                                        <button
                                            type="button"
                                            id="mainButton"
                                            onClick={() => handleSubmit()}
                                            disabled={disableAppDownload}
                                        >
                                            <p>
                                                {disableAppDownload ? "Coming Soon" : bannerHomeData.text4}
                                            </p>
                                        </button>
                                    </div>
                                    <p className={"errorText"}>&nbsp;{numberError}</p>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <SmsSentModal {...smsSentModalProps} />
            </>
            :
            <></>
    )
}

export default BannerNewComponentV2;