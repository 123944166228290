import React, {useEffect, useRef} from "react";

const CustomCarouselComponent = (props: React.PropsWithChildren) => {

    const INTERVAL_TIME: number = 3000
    let scrollAmount: number = 0;
    let childrenArray = React.Children.toArray(props.children);
    const timeout: React.MutableRefObject<NodeJS.Timeout | undefined> = useRef(undefined);

    // Children may contain false or null, so we should filter them
    // children may also contain string filled with spaces (in certain cases where we use jsx strings)
    let children = childrenArray.filter(child => {
        if (typeof child === "string") {
            return !!child.trim();
        }
        return !!child;
    });

    const enum CarouselType {
        Next,
        Prev
    }

    const handleResumeAndPause = () => {
        if (timeout.current) {
            clearInterval(timeout.current)
        }
        timeout.current = setInterval(handleAutoCarousel, INTERVAL_TIME);
    }

    useEffect(() => {
        if (children.length > 1) {
            timeout.current = setInterval(handleAutoCarousel, INTERVAL_TIME);
            let slides = document.getElementById("mySlides");
            slides?.addEventListener("scroll", () => {
                handleResumeAndPause()
            })
            return () => clearInterval(timeout.current)
        }
    }, [])

    const handleAutoCarousel = () => {
        let slides = document.getElementById("mySlides");
        if (slides) {
            nextSlides()
        }
    }

    const setScrollAmount = (scrollLeft: number, childClientWidth: number, carouselType: CarouselType, loop: number = 1) => {
        if (loop < 10) {
            loop++
        } else {
            return
        }
        if (scrollLeft >= 0 && scrollLeft <= childClientWidth * (children.length - 1)) {
            if (scrollLeft > scrollAmount && scrollLeft < scrollAmount + childClientWidth) {
                if (carouselType === CarouselType.Prev) {
                    scrollAmount = Math.max(scrollAmount + childClientWidth, 0)
                }
            } else if (scrollLeft !== 0 && scrollLeft % childClientWidth !== 0) {
                if (carouselType === CarouselType.Next) {
                    if (scrollLeft >= scrollAmount) {
                        scrollAmount = Math.max(scrollAmount + childClientWidth, 0)
                    } else {
                        scrollAmount = Math.max(scrollAmount - childClientWidth, 0)
                    }
                    setScrollAmount(scrollLeft, childClientWidth, carouselType, loop)
                } else {
                    if (scrollLeft >= scrollAmount) {
                        scrollAmount = Math.max(scrollAmount + childClientWidth, 0)
                    } else {
                        scrollAmount = Math.max(scrollAmount - childClientWidth, 0)
                    }
                    setScrollAmount(scrollLeft, childClientWidth, carouselType, loop)
                }
            } else if (scrollLeft % childClientWidth === 0 && scrollLeft !== scrollAmount) {
                scrollAmount = scrollLeft
            }
        }
    }

    const nextSlides = () => {
        let slides = document.getElementById("mySlides");
        if (slides) {
            setScrollAmount(slides.scrollLeft, slides.children[0].clientWidth, CarouselType.Next)
            if (scrollAmount === 0 && slides.scrollLeft > slides.children[0].clientWidth) {
                slides.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            } else if (scrollAmount < slides.children[0].clientWidth * (children.length - 1)) {
                slides.scrollTo({
                    top: 0,
                    left: Math.min(scrollAmount += slides.children[0].clientWidth, slides.children[0].clientWidth * (children.length - 1)),
                    behavior: 'smooth'
                });
            } else {
                scrollAmount = 0
                slides.scrollTo({
                    top: 0,
                    left: scrollAmount,
                    behavior: 'smooth'
                });
            }
        }
    };

    const prevSlides = () => {
        let slides = document.getElementById("mySlides");
        if (slides) {
            setScrollAmount(slides.scrollLeft, slides.children[0].clientWidth, CarouselType.Prev)
            if (scrollAmount > 0 || (scrollAmount === 0 && slides.scrollLeft > 0)) {
                slides.scrollTo({
                    top: 0,
                    left: Math.max(scrollAmount -= slides.children[0].clientWidth, 0),
                    behavior: 'smooth'
                });
            }
        }
    };
    return (
        <>
            <style jsx>{`
              #mySlides > * {
                transition: all 500ms;
              }

              #mySlides {
                display: flex;
                flex-wrap: nowrap;
                overflow-x: auto;
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
              }

              #mySlides::-webkit-scrollbar {
                display: none;
              }

              .child {
                min-width: calc(100%);
                display: flex;
                flex: 0 0 auto;
                flex-direction: row;
                flex-wrap: nowrap;
                align-content: center;
                justify-content: center;
                align-items: center;
              }

              /* Slideshow container */
              .slideshow-container {
                width: 100%;
              }

              /* Next & previous buttons */
              .prev, .next {
                cursor: pointer;
                position: absolute;
                top: 35%;
                width: auto;
                padding: 12px;
                margin-top: -22px;
                color: white;
                font-weight: bold;
                font-size: 18px;
                transition: 0.6s ease;
                border-radius: 0 3px 3px 0;
                user-select: none;
              }

              .prev {
                left: -15px;
              }

              /* Position the "next button" to the right */
              .next {
                right: -15px;
                border-radius: 3px 0 0 3px;
              }

              /* On hover, add a black background color with a little bit see-through */
              .prev:hover, .next:hover {
                background-color: rgba(0, 0, 0, 0.3);
              }

              /* Fading animation */
              .fade {
                animation-name: fade;
                animation-duration: 1.5s;
              }

              @keyframes fade {
                from {
                  opacity: .4
                }
                to {
                  opacity: 1
                }
              }

              @media screen and (max-width: 767px) {
                /* Next & previous buttons */
                .prev, .next {
                  top: 45%;
                }

                .prev {
                  left: 0;
                }

                .next {
                  right: 0;
                }
              }

              /* On smaller screens, decrease text size */
              @media only screen and (max-width: 300px) {
                .prev, .next, .text {
                  font-size: 11px
                }
              }
            `}</style>
            <div className="slideshow-container">
                <div id={"mySlides"}>
                    {
                        children.map((child, index) => {
                            return (
                                <div className={"child"} key={"children_" + index}>
                                    {child}
                                </div>
                            )
                        })
                    }
                </div>

                {
                    children.length > 1 && <>
                        <span className="prev" onClick={() => {
                            handleResumeAndPause()
                            prevSlides()
                        }}>❮</span>
                        <span className="next" onClick={() => {
                            handleResumeAndPause()
                            nextSlides()
                        }}>❯</span>
                    </>
                }
            </div>
        </>
    )
}

export default CustomCarouselComponent