import { useState } from "react";
import { ICountrySelectComponentProps } from "../../../lib/types/components.us";
import { countryCodes } from "../../../lib/utils/constants/CountryCode";
import { COUNTRY } from "../../../lib/utils/constants/LocaleAndCountryConstants";
import { debounce, getCountryFromLocale } from "../../../lib/utils/constants/PagesConstants";

const CountrySelect = (props: ICountrySelectComponentProps) => {
    const countryCode = getCountryFromLocale(props.locale) === COUNTRY.INDIA ? "+91" : props.countryCode
    const setCountryCode = props.setCountryCode
    const [searchText, setSearchText] = useState("");
    const [showDropDown, setShowDropDown] = useState(false);

    const getDebounceText = debounce<string>(setSearchText, 500);

    const reset = (dropDownValue: boolean) => {
        setShowDropDown(dropDownValue);
        setSearchText("");
    }

    const handleBlur = (event) => {
        const target: Element = event.relatedTarget as Element
        if (!target || (target.id !== "countrySearch" && target !== event.currentTarget)) {
            reset(false)
        }
    }

    return (
        <>
            <style jsx>{`
              .selectionContainer {
                position: relative;
                width: 100%;
                height: 100%;
                max-width: 75px;
                margin-right: 5px;
                pointer-events: ${props.disableAppDownload
                  ? "none"
                  : "auto"};              }

              .country-code-input {
                position: absolute;
                top: 0;
                left: 0;
                margin-right: 5px;
                height: 100%;
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                padding: .375rem .75rem;
                font-weight: 400;
                color: #ffffff;
                border-radius: 5px;
                text-align: center;
                transform: skewX(-10deg);
                box-shadow: 0 8px 10px 5px rgb(0 0 0 / 25%);
                background: rgba(0, 0, 0, 0.5);
                border: 1px solid rgba(255, 255, 255, .4);
                border-image-slice: 1;
              }
              
              .country-code-input > p{
                font-family: var(--font-primary);
                transform: skewX(10deg);
                margin: 0;
                font-size: 14px;
              }
              
              .country-code-input  svg{
                position: absolute;
                right: 5px;
              }

              .country-drop-down {
                position: absolute;
                top: 114%;
                left: -8px;
                z-index: 100;
                border-radius: 4px;
                max-height: 26vh;
                width: 25vw;
                padding: 10px;
                overflow: hidden;
                background-color: rgba(0, 0, 0, 1);
                color: white;
              }

              .srchContainer {
                position: sticky;
                top: 0;
                left: 0;
                right: 0;
              }

              .srchBox {
                background-color: rgba(255, 255, 255, 0);
                color: white;
                margin-bottom: 10px;
              }

              .srchBox::placeholder {
                color: rgba(255, 255, 255, 0.6);
              }

              .countries {
                max-height: 19vh;
                overflow: auto;
              }

              .country-element {
                margin: 2px;
                border-radius: 2px;
              }

              .country-element:hover {
                background: rgba(55, 92, 213, 0.51);
                cursor: pointer;
              }

              .selectionArrow {
                position: absolute;
                width: 10px;
                top: calc(50% - 5px);
                right: 5px;
              }
            `}</style>
            <div className={"selectionContainer"} tabIndex={0} onBlur={handleBlur}>
                <div className={"country-code-input"} onClick={() => reset(!showDropDown)}>
                    <p>{countryCode}</p>
                    <svg width="9" height="7" viewBox="0 0 13 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.5 11 13 0H0l6.5 11z" fill="#fff"/>
                    </svg>
                </div>
                {
                    showDropDown && getCountryFromLocale(props.locale) !== COUNTRY.INDIA &&
                    <div className={"country-drop-down"}>
                        <div className={"srchContainer"}>
                            <input type="search" id={"countrySearch"} className="srchBox form-control"
                                   placeholder={`${props.placeholder ? props.placeholder : "Search Country"}`}
                                   autoFocus={true}
                                   onChange={(e) => {
                                       getDebounceText(e.target.value);
                                   }}/>
                        </div>
                        <div className={"countries"}>
                            {
                                countryCodes.map((item, idx) => {
                                    if (item.name.toLowerCase().includes(searchText) || item.dial_code.includes(searchText)) {
                                        return <div className={"country-element"} key={"countryCodes_" + idx} onClick={() => {
                                            setCountryCode(item.dial_code);
                                            reset(false);
                                        }}>{`${item.name} (${item.dial_code})`}</div>
                                    }
                                })
                            }
                        </div>
                    </div>
                }

            </div>
        </>
    )
}

export default CountrySelect;